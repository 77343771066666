<template>
  
<navbar></navbar>
  <div>
    <main class="page-content">
      <div class="account-page-area">
      <div class="">
          <div class="row">
            <div class="col-lg-3 account_border-right">
              <SideMenu></SideMenu>
            </div>
            <div class="col-lg-9">
              <h2 class="comm-title">My Wishlist</h2>
              <div v-if="!items.length"><NotFound></NotFound></div>
              <div v-else>
                <div
                  class="row"
                >
                  <div
                    class="col-lg-3"
                    v-for="VehicleDetails in items"
                    :key="VehicleDetails.vehId"
                    style="margin-bottom: 15px; padding: 0px"
                  >
                    <div>
                      <div class="">
                        <!--new-main-tile-->
                        <section>
                          <div class="" style="margin: 0px 6px">
                            <div class="">
                              <div class="carTileWrap shine-overlay">
                                <div class="shine"></div>
                                <div
                                  class="cwrapImage"
                                  style="position: relative"
                                >
                                  <router-link
                                    :to="{
                                      name: 'AuctionDetails',
                                      params: {
                                        url_key: VehicleDetails.aucName,
                                      },
                                    }"
                                  >
                                    <img
                                      :src="path + VehicleDetails.vehImage1"
                                      class="img-fluid img-responsive border5px"
                                  /></router-link>    <div class="topleft">
                                    <div>
                                      <CountDown
                                        :customstyle="'background-color: beige;'"
                                        :deadline="VehicleDetails.aucExtDate"
                                      ></CountDown>
                                    </div>
                                  </div>
                                  <div class="topright">
                                    <div style="display: flex">
                                      <!-- Wishlist -->
                                      <div class="bidlistDetailswrapwidth15">
                                        <div>
                                          <div
                                            class="button-bidzzz active"
                                            @click="
                                              rmvfromWhishlist(VehicleDetails)
                                            "
                                          >
                                            <svg
                                              width="35px"
                                              height="25px"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <g
                                                fill="none"
                                                fill-rule="evenodd"
                                              >
                                                <path
                                                  class="heart-stroke"
                                                  d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                                  fill="#0090E3"
                                                />
                                                <path
                                                  class="heart-full"
                                                  d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                                  fill="red"
                                                />
                                                <path
                                                  class="heart-lines"
                                                  d="M26,4 L30.6852129,0.251829715"
                                                  stroke="red"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                />
                                                <path
                                                  class="heart-lines"
                                                  d="M2.314788,4 L7.00000086,0.251829715"
                                                  stroke="red"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  transform="matrix(-1 0 0 1 10.314788 1)"
                                                />
                                                <path
                                                  class="heart-lines"
                                                  d="M27,12 L33,12"
                                                  stroke="red"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                />
                                                <path
                                                  class="heart-lines"
                                                  d="M0,12 L6,12"
                                                  stroke="red"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  transform="matrix(-1 0 0 1 7 1)"
                                                />
                                                <path
                                                  class="heart-lines"
                                                  d="M24,19 L28.6852129,22.7481703"
                                                  stroke="red"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                />
                                                <path
                                                  class="heart-lines"
                                                  d="M4.314788,19 L9.00000086,22.7481703"
                                                  stroke="red"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  transform="matrix(-1 0 0 1 14.314788 1)"
                                                />
                                              </g>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                   
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="vehicleName">
                                    <router-link
                                      :to="{
                                        name: 'AuctionDetails',
                                        params: {
                                          url_key: VehicleDetails.aucName,
                                        },
                                      }"
                                    >
                                      <h5>
                                        {{ VehicleDetails.brandName }}
                                        {{ VehicleDetails.modelName }}
                                        {{ VehicleDetails.manYear }}
                                      </h5>
                                    </router-link>

                     
                                  </div>
                                  <div>
                                    <div class="row" style="margin: 0px">
                                      
                                    </div>
                                    <hr style="margin: 0px" />
                                    <div class="row">
                                      <div class="col-12">
                                        <div class="text-center">
                                          <div>
                                            <p class="tile-small-txt">
                                              CURRENT BID PRICE
                                            </p>
                                            <h4
                                              style="
                                                font-size: 18px;
                                                font-weight: 700;
                                              "
                                            >
                                              ${{
                                                VehicleDetails.latestBidAmount
                                              }}
                                            </h4>
                                            <button
                                              :disabled="Buttondisabled"
                                              class="tile-bid-now"
                                              tabindex="-1"
                                              @click="showModal(VehicleDetails)"
                                            >
                                              BID NOW
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div class="text-center">
                                         
                                        </div>
                                      </div>
                                    </div>

                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- .container -->
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <!-- modal -->
        <div class="overlay-bid-btn" v-if="openModel && userinfo">
            <button class="close-bid-btn bid-popup-close" @click="closeModal()">
              x
            </button>
            <h4>Bid Information</h4>           
            <p>Latest Bid Amount : $ {{ amount }}</p>
            <div class="auct-bid-pop-left-wrap">
              <div class="auct-bid-pop-left">
                <button class="btn btn-secondary" :class="{ 'buttonDisabled': isSubmitButtonDisabled }"
                  :disabled="isSubmitButtonDisabled" @click="decreaseBidAmount()"><span>-</span></button>
                <div class="auct-bid-pop-left-inner">
                  <input type="number" v-model="bidAmount" class="form-control" style="height: 37px" />
                </div>
                <button class="btn btn-secondary" @click="increaseBidAmount()"><span>+</span></button>
              </div>
              <button class="bid-info-sub" :class="{ 'buttonDisabled': isSubmitButtonDisabled }" @click="SubmitBid()"
                :disabled="isSubmitButtonDisabled">
                Submit
              </button>
            </div>
          </div>
        <div class="row">
        <div class="col-lg-12">
          <div class="uren-paginatoin-area">
            <div class="row">
              <div class="col-lg-12">
                <PageNation
                  :vechiclePage="AuctionsDetails"
                  @pageChange="PageRout"
                ></PageNation>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      
    </main>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
import NotFound from "@/components/modules/NotFound.vue";
import PageNation from "@/components/modules/PageNation.vue";
import CountDown from "@/components/modules/CountDown.vue";
import userService from "@/services/user.service";
import SideMenu from "@/components/sideMenu.vue";
import Config from "@/config";
export default {
  name: "Winnings",
  components: {
    SideMenu,
    CountDown,
    PageNation,
    NotFound,
    navbar
  },
  data() {
    return {
      openModel: false,
      amount: "",
      AuctionsDetails: "",
      userinfo: "",
      custId: "",
      bidAmount: "",
      Buttondisabled: false,
      selectedAuction: "",
      message: "",
      items: [],
      path: Config.BASE_URL_IMG,
      BidTimeOut: Config.bidModalTimeout,
      perPage: Config.itemPerPageAuction,
      currentPage: 1,
      minIceament: "",
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isSubmitButtonDisabled() {
      return this.bidAmount <= this.amount;
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    if (this.userinfo) {
      if (typeof this.userinfo.customerId != "undefined") {
        this.custId = this.userinfo.customerId;
      } else {
        console.log("vendor");
      }
    }
  },
  mounted() {
    this.getCustomerWishlists();
  },

  methods: {
     // minIceament

     decreaseBidAmount() {

if (this.bidAmount >= this.amount) {
  this.bidAmount -= this.minIceament;
}

},
increaseBidAmount() {
this.bidAmount += this.minIceament;
},
    PageRout(userData) {
      // console.log(userData);
      this.items = userData;
    },
    showModal(e) {
      this.openModel = !this.openModel;
      this.amount = e.latestBidAmount;
      this.minIceament = e.aucMinBid;
      this.bidAmount = e.latestBidAmount + this.minIceament;
      this.selectedAuction = e;
      this.message = "";
    },
    closeModal() {
      // this.isBitEntryModelActive = false;
      var self = this;

      setTimeout(function () {
        self.hideModal();
      }, this.BidTimeOut);
    },
    hideModal() {
      this.openModel = false;
      this.message = "";
    },
    isMultipleOfHundred(number) {
  return number % this.minIceament === 0;
},
    SubmitBid() {
      // console.log(this.bidAmount);
  this.message ="";
      if (this.custId && this.bidAmount !== "" && this.bidAmount !== null) {
        let cusid = this.custId;
        let bidamount = parseInt(this.bidAmount);
        if(!this.isMultipleOfHundred(this.bidAmount)){
            this.$toast.warning("Please enter a multiple of 100", {
              position: "top",
              duration: 3000,
              queue: true
            });
          }else {
        userService
          .addbid(this.selectedAuction, cusid, bidamount)
          .then((response) => {
            this.message = response.data.Message;
            this.closeModal();
            this.getCustomerWishlists();
            this.message ="";
            this.$toast.success(" Suceesfully added bid", {
              position: "top",
              duration: 3000,
            });
          })
          .catch((error) => {
            this.message =
              (error.response && error.response.data.Message) ||
              error.message ||
              error.toString();
              this.message="";
            this.$toast.error(error.response.data.Message, {
              position: "top",
              duration: 3000,
            });
            
          }); }
      } else {
   
        this.$toast.error("Please enter a valid bid amount", {
              position: "top",
              duration: 3000,
            });
      }
    },
    rmvfromWhishlist(item) {
      userService.rmvfromWhishlist(item, this.custId).then((response) => {
        console.log(response);
        location.reload();
      });
    },
    getCustomerWishlists() {
      let customerId = this.custId;

      userService
        .getCustomerWishlists(customerId)
        .then((resp) => {
          this.AuctionsDetails = resp.data.Data;
          this.items = this.AuctionsDetails.slice(
            (this.currentPage - 1) * this.perPage,
            this.currentPage * this.perPage
          );
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>
<style scoped>
.carTileWrap {
  padding: 5px;
  background-color: #f2f2f2 !important;
  border-radius: 5px;
  box-shadow: 0px 0px 4px #a2a2a2;
}
.uren-sidebar-catagories_area .uren-sidebar_categories {
  background-color: #ebeff5;
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.uren-sidebar-catagories_area
  .uren-sidebar_categories
  .uren-categories_title
  h5 {
  text-transform: uppercase;
  margin-bottom: 0;
  padding-bottom: 5px;
  color: black;
}
.uren-sidebar-catagories_area
  .uren-sidebar_categories
  .sidebar-checkbox_list
  > li
  > a {
  color: black;
}
.subBtnzz {
  background-image: linear-gradient(to bottom right, #1d1b2b, #004a6d);
  color: white;
  font-weight: 600;
}
.bidamountzz {
  font-size: 20px;
  font-weight: 600;
}
.heart-stroke {
  fill: white;
  stroke: #666;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
  animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
  animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
  animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.bidBtn {
  background-color: #0d082fd6 !important;
  padding: 4px 20px !important;
}
.marginbidtop {
  margin-top: 0px !important;
}
.marginbidtop h4 {
  font-size: 20px;
}

.spanzz {
  font-size: 12px;
}
.bidColorbtn {
  padding: 3px 20px;
}
.bidBtn {
  padding: 3px 6px;
}
.marginbidtop {
  margin-top: 5px;
}
.heart-stroke {
  fill: white;
  stroke: #666;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
  animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
  animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
  animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.subBtnzz {
  background-image: linear-gradient(to bottom right, #1d1b2b, #004a6d);

  color: white;
  font-weight: 600;
}
.bidamountzz {
  font-size: 20px;
  font-weight: 600;
}
.heart-stroke {
  fill: white;
  stroke: #ddd;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
  animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
  animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
  animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.buttonDisabled {
  opacity: 0.2;
}
</style>
